<template>
  <!-- eslint-disable  -->
  <div>
    <div class="d-sm-flex mb-3 text-Heading6 Medium d-none">
      {{ $store.getters['app/getRouteTitle'] }}
      <v-spacer></v-spacer>
    </div>

    <FilterBox
      :open.sync="isFilterOpen"
      v-model="filter"
      :loading="isLoading"
      :options="filterOptions"
      @search="fetchData"
    />

    <SizeBox height="24" />

    <v-card
      outlined
      :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
    >
      <v-card-text class="d-flex align-center pb-0 pb-sm-4">
        <v-spacer></v-spacer>
        <RefreshBtn :fetchData="fetchData" :loading="isLoading" />

        <SizeBox width="14" />
        <ExportBtn
          :items="result.list"
          :headers="headers"
          :labels="{
            'capital_log_type.key': capitalLogTypeMaps
          }"
          :file-name="`${
            $store.getters['app/getRouteTitle']
          } ${$moment().format('YYYY-MM-DD_H:mm:ss')}`"
          sheet-name="sheetName"
        />
      </v-card-text>
      <DataTable
        :list="result.list"
        :fields="headers"
        :total="result.total"
        :loading="isLoading"
        :replace="[
          'platform_order_no',
          'created_at|formatTime',
          'collect_channel_type_name'
        ]"
        @get-list="fetchData"
        :labels="{
          'capital_log_type.key': capitalLogTypeMaps
        }"
      >
        <template #item.platform_order_no="{ item }">
          <div>
            <div class="text-no-wrap">{{ item.platform_order_no || '-' }}</div>
            <div class="text-no-wrap">{{ item.merchant_order_no || '-' }}</div>
          </div>
        </template>
        <template #item.collect_channel_type_name="{ item }">
          <v-chip color="Primary050" small>銀行轉帳</v-chip>
        </template>
      </DataTable>
    </v-card>
    <FunctionFab
      @info="isInfoOpen = true"
      @refresh="fetchData()"
      @open-filter="isFilterOpen = true"
      :no-filter="!filterOptions.length"
      :no-info="!Object.entries(result.header_info).length"
    />
  </div>
</template>

<script>
import { getCollectChannelTypes } from '@/api/collectChannels';
import { getCapitalLogTypes } from '@/api/capitalLogs';
import { getCapitalLogList } from '@/api/merchantCenter/capitalLogs';

const capitalLogTypeMaps = [
  { text: '交易訂單', value: 'collect_order', color: 'Primary100' },
  { text: '手動增加', value: 'manual_add', color: 'Primary200' },
  { text: '手動減少', value: 'manual_reduce', color: 'Primary300' },
  { text: '商戶結算', value: 'merchant_pay', color: 'Error200' },
  { text: 'API 代付', value: 'api_pay', color: 'Error300' },
  { text: '駁回商戶結算', value: 'cancel_merchant_pay', color: 'Error400' },
  { text: '交易訂單手續費', value: 'collect_order_fee', color: 'Error300' },
  { text: '駁回 API 代付', value: 'cancel_api_pay', color: 'Error500' },
  { text: '凍結', value: 'freeze', color: 'Surface200' },
  { text: '解凍', value: 'unfreeze', color: 'Surface300' },
  { text: '提成', value: 'commission', color: 'Secondary050' },
  { text: '扣除 API 代付手續費', value: 'api_pay_fee', color: 'Secondary100' },
  {
    text: '退回 API 代付手續費',
    value: 'cancel_api_pay_fee',
    color: 'Secondary200'
  },
  {
    text: '扣除商戶結算手續費',
    value: 'merchant_pay_fee',
    color: 'Secondary300'
  },
  {
    text: '退回商戶結算手續費',
    value: 'cancel_merchant_pay_fee',
    color: 'Secondary400'
  }
];

export default {
  //
  data() {
    return {
      ...this.$clone(this.$commonSchema),
      capitalLogTypeMaps,
      headers: [
        {
          text: '平台單號\n/\n商戶單號',
          value: 'platform_order_no',
          align: 'center',
          width: '200px',
          join: 'merchant_order_no'
        },
        {
          text: '商戶 ID',
          value: 'merchant.serial',
          align: 'center',
          width: '80px'
        },
        {
          text: '類型',
          value: 'capital_log_type.key',
          align: 'center',
          width: '200px'
        },
        {
          text: '所屬代理',
          value: 'agent',
          align: 'center',
          width: '150px'
        },
        {
          text: '通道',
          value: 'collect_channel_type_name',
          align: 'center',
          width: '80px'
        },
        {
          text: '原始資金',
          value: 'original_amt',
          align: 'center',
          width: '150px'
        },
        {
          text: '變動資金',
          value: 'change_amt',
          align: 'center',
          width: '150px'
        },
        { text: '資金餘額', value: 'amt', align: 'center', width: '1５0px' },
        {
          text: '變動時間',
          value: 'created_at',
          dataFormat: this.$root.$options.filters.formatTime,
          align: 'center',
          width: '180px'
        },
        { text: '備註', value: 'note', align: 'center', width: '180px' }
      ],
      filterOptions: [
        {
          label: '平台單號',
          type: 'text',
          name: 'filter.platform_order_no'
        },
        {
          label: '建立時間',
          type: 'timeSet',
          name: 'filter.created_at',
          range: true,
          withTime: true
        },
        {
          label: '通道',
          type: 'autocomplete',
          name: 'filter.collect_channel_id',
          api: getCollectChannelTypes
        },
        {
          label: '明細類型',
          type: 'autocomplete',
          name: 'filter.capital_log_type_id',
          api: getCapitalLogTypes
        }
      ]
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData(condition = this.condition) {
      this.isLoading = true;
      this.condition = condition;
      const capitalLogs = await getCapitalLogList({
        ...this.filter,
        ...condition
      });

      if (!capitalLogs.error) {
        this.result.total = capitalLogs.total;
        this.result.page = capitalLogs.page;
        this.$set(
          this.result,
          'list',
          capitalLogs.items.map((item) => ({
            ...item,
            agent:
              (item.merchant &&
                item.merchant.merchant &&
                item.merchant.merchant.name) ||
              '-',
            platform_order_no:
              (item.pay_order && item.pay_order.platform_order_no) ||
              (item.collect_order && item.collect_order.platform_order_no) ||
              '-',
            merchant_order_no:
              (item.pay_order && item.pay_order.merchant_order_no) ||
              (item.collect_order && item.collect_order.merchant_order_no) ||
              '-'
          }))
        );
      }
      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
