var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-sm-flex mb-3 text-Heading6 Medium d-none"},[_vm._v(" "+_vm._s(_vm.$store.getters['app/getRouteTitle'])+" "),_c('v-spacer')],1),_c('FilterBox',{attrs:{"open":_vm.isFilterOpen,"loading":_vm.isLoading,"options":_vm.filterOptions},on:{"update:open":function($event){_vm.isFilterOpen=$event},"search":_vm.fetchData},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('SizeBox',{attrs:{"height":"24"}}),_c('v-card',{attrs:{"outlined":"","color":_vm.$vuetify.breakpoint.smAndDown ? 'transparent' : null}},[_c('v-card-text',{staticClass:"d-flex align-center pb-0 pb-sm-4"},[_c('v-spacer'),_c('RefreshBtn',{attrs:{"fetchData":_vm.fetchData,"loading":_vm.isLoading}}),_c('SizeBox',{attrs:{"width":"14"}}),_c('ExportBtn',{attrs:{"items":_vm.result.list,"headers":_vm.headers,"labels":{
          'capital_log_type.key': _vm.capitalLogTypeMaps
        },"file-name":`${
          _vm.$store.getters['app/getRouteTitle']
        } ${_vm.$moment().format('YYYY-MM-DD_H:mm:ss')}`,"sheet-name":"sheetName"}})],1),_c('DataTable',{attrs:{"list":_vm.result.list,"fields":_vm.headers,"total":_vm.result.total,"loading":_vm.isLoading,"replace":[
        'platform_order_no',
        'created_at|formatTime',
        'collect_channel_type_name'
      ],"labels":{
        'capital_log_type.key': _vm.capitalLogTypeMaps
      }},on:{"get-list":_vm.fetchData},scopedSlots:_vm._u([{key:"item.platform_order_no",fn:function({ item }){return [_c('div',[_c('div',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.platform_order_no || '-'))]),_c('div',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.merchant_order_no || '-'))])])]}},{key:"item.collect_channel_type_name",fn:function({ item }){return [_c('v-chip',{attrs:{"color":"Primary050","small":""}},[_vm._v("銀行轉帳")])]}}])})],1),_c('FunctionFab',{attrs:{"no-filter":!_vm.filterOptions.length,"no-info":!Object.entries(_vm.result.header_info).length},on:{"info":function($event){_vm.isInfoOpen = true},"refresh":function($event){return _vm.fetchData()},"open-filter":function($event){_vm.isFilterOpen = true}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }